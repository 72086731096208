.legal-page-container {
  $text-secondary-color: #666666;
  $text-main-color: #222222;

  display: grid;
  grid-template-columns: 330px 1fr;
  padding: 50px 20px;

  @media (max-width: 48em) {
    grid-template-columns: 100%;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 1.3rem;
    font-weight: 500;
    background: #f5f7fc;
    color: #0d0c52;
    padding: 40px;
    border-radius: 0.8rem;
    word-break: break-word;

    a {
      font-size: 16px;

      &.active {
        color: #ffb21c;
      }

      &:active, &:focus {
        outline: none;
        box-shadow: none!important;
      }
    }
  }

  &__content {
    padding: 16px 30px;
    background: #fff;
    line-height: 23px;
    z-index: 100;
    word-break: break-word;

    .paragraphSection {
      font-weight: bolder;
      color: #333;
    }


    ul {
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 40px;
    }

    li {
      font-size: 16px;
    }

    h1 {
      margin-block-start: 0.67em;
      margin-block-end: 0.67em;
      font-weight: bold;
    }

    h2 {
      margin-block-start: 0.83em;
      margin-block-end: 0.83em;
      font-weight: bold;
    }

    h3 {
      margin-block-start: 1em;
      margin-block-end: 1em;
      font-weight: bold;
    }

    h4 {
      margin-block-start: 1.33em;
      margin-block-end: 1.33em;
      font-weight: bold;
    }

    h5 {
      margin-block-start: 1.67em;
      margin-block-end: 1.67em;
      font-weight: bold;
    }

    h6 {
      margin-block-start: 2.33em;
      margin-block-end: 2.33em;
      font-weight: bold;
    }

    p {
      color: $text-secondary-color;
      font-size: 16px;
      margin-top: 1em;
      margin-bottom: 1em;

      a {
        font-weight: 600;
        color: $text-main-color;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    h3 {
      font-size: 28px;
      font-weight: 600;
      line-height: 34px;
    }

    h1 {
      font-size: 40px;
      font-weight: 600;
      line-height: 44px;
    }

    h4 {
      font-weight: 400;
      font-style: normal;
      font-size: 20px;
      line-height: 26px;
      color: $text-secondary-color;
    }
  }

  .dottedList {
    p {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      a {
        font-weight: 600;
        color: $text-main-color;
        text-decoration: underline;
        cursor: pointer;
      }

      div {
        a {
          font-weight: 600;
          color: $text-main-color;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    p::before {
      content: '';
      width: 16px;
      min-width: 16px;
      height: 16px;
      background: white;
      border-radius: 14px;
      border: 5.71px solid #23B6EC;
      margin-right: 20px;
      font-size: 16px;
    }
  }
}
